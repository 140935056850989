@import '../libs/bootstrap/variables';
@import '../functions';
@import '../settings';
@import 'bootstrap/scss/mixins/breakpoints';

$header-height: 70px;

#header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    background-color: transparent;
    padding: 7.5px 40px;
    transition: all 0.4s ease-in;
    top: 0;

    &.active {
        background-color: #fff;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
    }

    @include media-breakpoint-down(sm) {
        padding: 10px 10px;
    }

    @media (max-width: 560px) {
        #name-block {
            overflow-x: hidden;
        }

        #logout-span {
            display: none;
        }
    }

    @media (min-width: 470px) {
        .client-settings-row {
            display: none;
        }
    }

    @media (max-width: 470px) {
        #name-block {
            text-align: left;
            margin-right: 10px;
        }

        .main-settings-part {
            display: none;
        }

        .client-settings-button-row {
            display: block;
            text-align: left;
        }

        .client-settings-button-row > * {
            display: inline-block;
            vertical-align: middle;
        }
    }

    @media (max-width: 410px) {
        #name-block {
            text-align: left;
            overflow-x: hidden;
        }
    }

    @media (max-width: 390px) {
        #name-block {
            text-align: left;
            overflow-x: hidden;
        }

        #name-icon {
            display: none;
        }
    }

    @include media-breakpoint-up(md) {
        padding-left: 10px;
        padding-right: 10px;
        background-color: rgba(255, 255, 255, 0.55);
    }
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: rem-calc(1080);
    margin: 0 auto;

    &__links {
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;

        &_authenticated {
            gap: 20px;
        }

        .btns__header {
            color: inherit;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
        }

        .fa:before {
            font-size: 18px;
            color: inherit;
        }

        @include media-breakpoint-up(md) {
            padding-left: 0;
            gap: 40px;
        }

        @include media-breakpoint-down(sm) {
            gap: 15px;
        }

        @include media-breakpoint-down(395px) {
            gap: 10px;
        }

        .vertical-line {
            width: 1px;
            height: 25px;
            background-color: rgba(129, 0, 127, 0.35);
        }

        li {
            line-height: unset;
            color: #81007f;

            &:hover {
                color: #9b0098;
                text-decoration: none;
            }
        }

        .client-settings-row {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            & .client-settings-row-menu {
                position: absolute;
                background-color: #fff;
                border-radius: 7px;
                display: none;
                flex-direction: column;
                gap: 5px;
                padding: 1rem;
                box-shadow: 0 0 10px rgba(#000, 0.2);
                min-width: 10rem;
                top: 140%;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s ease;
                z-index: 2;

                &.show-settings {
                    display: flex;
                }

                & > a {
                    color: #81007f;

                    &:hover {
                        color: #9b0098;
                        text-decoration: none;
                    }
                }
            }
        }

        .client-settings {
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;

            & .client-settings-menu {
                position: absolute;
                background-color: #fff;
                border-radius: 7px;
                display: none;
                flex-direction: column;
                gap: 5px;
                padding: 1rem;
                box-shadow: 0 0 10px rgba(#000, 0.2);
                min-width: 10rem;
                top: 140%;
                left: 50%;
                transform: translateX(-50%);
                transition: all 0.3s ease;
                z-index: 2;

                &.show-settings {
                    display: flex;
                }

                & > a {
                    color: #81007f;

                    &:hover {
                        color: #9b0098;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
